@import "../../styles/vars.scss";

.header-space {
  > {
    .ant-space-item {
      height: 100%;
    }
  }
}

header {
  display: block;
}

.dt-header {
  position: relative;
  z-index: 99;
  height: 64px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
}

.dt-header__container {
  margin: auto;
  display: flex;
  height: 100%;
}

.dt-brand {
  background-color: #ffffff;
  color: #545454;
  padding: 0 2.5rem;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.dt-sidebar--fixed {
  .dt-brand {
    position: fixed;
    z-index: 99;
    transition: all 0.2s ease;
  }
}

.dt-brand__logo {
  margin: 0;
}

.dt-brand__logo-link {
  display: flex;
  align-items: center;
  font-size: 16px;

  i {
    font-size: 30px;
    margin-left: 5px;
  }
}

.dt-header--fixed {
  .dt-header {
    position: fixed;
    z-index: 999;
    width: 100%;
  }
}

.dt-brand__logo-link {
  color: #1890ff;
  display: flex;
  align-items: center;
  font-size: 20px !important;

  &:hover {
    color: #40a9ff;
  }
}

.header-dropdown {
  &-wrapper {
    border-left: 1px solid rgba($color-gray, 0.1);
    padding: 0 15px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $color-blue-light;
    }
  }

  &-item {
    padding: 5px 12px 5px 10px !important;
    border-bottom: 1px solid rgba($color-gray, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    min-height: 40px;
    border-left: 3px solid transparent;
    color: $color-gray;
    transition: all 0.3s ease !important;

    &.item-red {
      &:hover {
        color: $color-red;
        border-left: 3px solid $color-red;
      }
    }

    &:hover {
      border-left: 3px solid $color-blue;
      color: $color-blue;
    }
  }
}

@media screen and (min-width: 1367px) {
  .dt-brand {
    width: calc(240px + 40px);
  }
}

