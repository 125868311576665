.remove-group-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
  cursor: pointer;
}

.slow {
  transition: transform 0.5s cubic-bezier(.47, 1.64, .41, .8);
}