@import '../styles/vars.scss';
@import '../styles/mixins.scss';

.p-primary {
  line-height: 24px !important;
  color: $color-gray !important;
  font-size: 16px !important;
  margin-bottom: 30px;
}

.accent {
  color: $color-red;
  font-size: 20px;
  font-weight: 400;
  text-shadow: 0 10px 15px rgba(245, 34, 45, 0.2),
  0 4px 6px rgba(245, 34, 45, 0.1);
}

.badge {
  &-blue,
  &-red,
  &-purple,
  &-orange {
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    text-shadow: none;
    border-radius: 4px;
    padding: 2px 12px;
    color: #ffffff;
  }

  &-blue {
    @include getShadowBox($color-blue);
  }

  &-red {
    @include getShadowBox($color-red);
  }

  &-purple {
    @include getShadowBox($color-purple);
  }

  &-orange {
    @include getShadowBox($color-volcano);
  }
}

ul.list-primary {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    color: $color-gray;
    font-size: 16px;
    padding: 3px 0;
  }
}

ul.list-secondary {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 0;

  li {
    color: $color-gray;
    font-size: 16px;
    padding: 3px 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -18px;
      top: 12px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 2px solid $color-blue-dark;
      //background: #fff;
    }
  }
}

.block-primary {
  background: $color-blue-light;
  padding: 15px 20px;
  border-radius: 8px;
}

.p-important {
  color: #5c0011;
  padding: 16px;
  margin-bottom: 0;
  background: #fff1f0;
  border-radius: 4px;
  border: 1px solid #ffa39e;
}

.dot-step {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  margin-bottom: 0;
  color: #ffffff;

  @include getShadowBox($color-blue);
}

.ant-timeline-item-tail {
  border-left: 1px solid #f0f0f0;
}

.sub_timeline {
  .ant-timeline-item-head {
    top: 16px !important;
    color: $color-blue;
  }

  .ant-timeline-item-tail {
    top: 26px !important;
  }
}