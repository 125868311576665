.dt-footer {
  background-color: #ffffff;
  color: #545454;
  padding: 1.2rem 3.2rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  max-height: 60px;
  margin-top: auto;
  border-top: solid 1px #e8e8e8;
  width: 100%;
}