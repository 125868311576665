// Header dropdown

$color-blue: #1890ff;
$color-gray: #888888;
$color-light-gray: #e0e0e0;
$color-blue-light: #e6f7ff;
$color-border: #f1f1f1;

.header-dropdown {
  &-wrapper {
    border-left: 1px solid rgba(136, 136, 136, .1);
    padding: 0 15px;
    display: flex
  ;
    align-items: center;
    transition: background-color .3s ease;
    height: 100%;
    line-height: 1;

    &:hover {
      background-color: $color-blue-light;
    }
  }

  &-item {
    padding: 5px 12px 5px 10px !important;
    border-bottom: 1px solid rgba($color-gray, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    min-height: 40px;
    border-left: 3px solid transparent;
    color: $color-gray;
    transition: all 0.3s ease !important;

    &:hover {
      border-left: 3px solid $color-blue;
      color: $color-blue;
    }
  }
}

.interfaces {
  &__title {
    margin: 0;
    flex-shrink: 0;
    font-size: 18px;
  }

  &__search {
    margin-right: 40px;
    padding: 10px;
    width: 100%;
  }

  &__box {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    // grid-template-rows: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
  }
}

.interface {
  &__card {
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    border-radius: 8px;
    border-left: 3px solid transparent;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
    transition: all 0.25s;

    &:hover {
      border-color: $color-blue;  
      box-shadow: 0 4px 15px -2px rgba(0, 0, 0, 0.25);
      transform: translateY(-7px);
    }
  }

  &__content {
    flex-grow: 1;
    padding: 18px 8px 18px 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  &__icon {
  }

  &__title {
    margin: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.88);
  }

  &__description {
    margin: 0;
    font-size: 16px;
    color: $color-gray;
  }


  &__link {
    text-align: center;
    word-break: break-all;
  }

  &__actions {
    flex: 0 0 50px;
  }

  &__button {
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    // writing-mode: vertical-rl;
    border: 0;
    outline: 0;

    &--link {
      width: 100%;
      height: 100%;
      border-left: 1px solid darken($color-blue, 5%);
      color: #ffffff;
      background: linear-gradient(to left, lighten($color-blue, 15%), $color-blue);
      box-shadow: inset 5px 0 5px rgba(0, 0, 0, 0.1);
      
      &:hover {
        background: linear-gradient(to left, lighten($color-blue, 5%), darken($color-blue, 10%));
      }

      span {
        writing-mode: vertical-rl;
      }  
    }
  }
}