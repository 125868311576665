@import '../../../styles/vars';
@import '../../../styles/mixins';

.icon-shadow {
  &-dark {
    @include getShadow($color-dark)
  }

  &-gray {
    @include getShadow($color-gray)
  }

  &-red {
    @include getShadow($color-red)
  }

  &-volcano {
    @include getShadow($color-volcano)
  }

  &-orange {
    @include getShadow($color-orange)
  }

  &-green {
    @include getShadow($color-green)
  }

  &-cyan {
    @include getShadow($color-cyan)
  }

  &-blue {
    @include getShadow($color-blue)
  }

  &-geekblue {
    @include getShadow($color-geekblue)
  }

  &-purple {
    @include getShadow($color-purple)
  }
}
