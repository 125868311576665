.info-block {
  &:not(:first-child) {
    padding-top: 16px;
  }

  &-icon,
  &-header {
    color: #8c8c8c
  }

  &-header {
    margin-bottom: 10px;
  }
}