@import "../../styles/mixins.scss";
@import "../../styles/vars.scss";

.tag {
  &-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 24px !important;
    height: 24px;
  }

  &-dark {
    @include getShadowBox($color-dark)
  }

  &-gray,
  &-default {
    @include getShadowBox($color-gray)
  }

  &-snow {
    @include getShadowBox(#bfbfbf)
  }

  &-red,
  &-group {
    @include getShadowBox($color-red)
  }

  &-volcano {
    @include getShadowBox($color-volcano)
  }

  &-orange,
  &-service {
    @include getShadowBox($color-orange)
  }

  &-green {
    @include getShadowBox($color-green)
  }

  &-blue,
  &-classic_user {
    @include getShadowBox($color-blue)
  }

  &-geekblue,
  &-user {
    @include getShadowBox($color-geekblue)
  }

  &-purple {
    @include getShadowBox($color-purple)
  }

  &-magenta {
    @include getShadowBox($color-magenta)
  }

  &-cyan,
  &-phantom {
    @include getShadowBox($color-cyan)
  }
}