@mixin getShadow($color) {
  filter: drop-shadow(0 10px 15px rgba($color, 0.2)) drop-shadow(0 4px 6px rgba($color, 0.1));
}

@mixin getShadowBox($color) {
  background: $color !important;
  color: #ffffff !important;

  box-shadow: rgba($color, 0.2) 0 10px 15px -3px,
  rgba($color, 0.1) 0 4px 6px -2px !important;

  &-light {
    color: $color;
    border: 1px solid rgba($color, 0.12);
    background: rgba($color, 0.1);
    display: inline-flex;
    align-items: center;
    min-height: 24px;

    &.round {
      color: #8c8c8c;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -8px !important;
      height: 32px;
      width: 32px !important;
      padding: 0;
      border-radius: 50%;
      background: #fafafa;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.tag-button {
    box-shadow: none !important;

    &:hover {
      background-color: darken($color, 10%) !important;
    }

    &.disabled {
      background-color: $color !important;
      cursor: not-allowed;
    }
  }

  &.outlined {
    border: 2px solid rgba($color, 0.8) !important;
    color: $color !important;
    background: none !important;
    box-shadow: none !important;

    &:hover {
      color: #fff !important;
      //border: none !important;
      background: $color !important;
    }
  }
}