@import '../../styles/mixins.scss';
@import '../../styles/vars.scss';

.container {
  width: 100%;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  margin-right: auto;
  margin-left: auto;
}

.error-container {
  margin-top: 150px;
}

.error-first-section {
  padding-top: 50px;
}

.error-row {
  @mixin getColor($color) {
    border-top: 1px solid rgba($color, 0.1) !important;

    &:nth-child(odd) td {
      background-color: rgba($color, 0.05) !important;
    }

    td {
      background-color: rgba($color, 0.05) !important;
      border-top: 1px solid rgba($color, 0.1) !important;
      border-bottom: 1px solid rgba($color, 0.1) !important;
      //color: $color;

      &:first-child {
        border-left: 1px solid rgba($color, 0.1) !important;
      }

      &:last-child {
        border-right: 1px solid rgba($color, 0.1) !important;
      }
    }
  }

  &-error {
    @include getColor($color-red)
  }
}