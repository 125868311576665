@import '../../../../styles/vars.scss';

.card-action {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background-color: $color-blue-light;
  border-radius: 0 0 6px 6px;
  box-shadow: $color-blue 0 3px 0 0;
  color: $color-blue;
  font-size: 1.8rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: $color-blue;
    color: #fff;
  }
}

.card-collapse {
  position: relative;

  &.ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-header {
    display: none !important;

    .ant-collapse-arrow {
      transition: all 0.3s ease-out;
      margin: 0 8px 0 0 !important;
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}