.dt-root {
  opacity: 1;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

.dt-content {
  //padding: 3.2rem 3.2rem 0.2rem;
  flex: 1;
  -ms-flex: 1;
}

.dt-content-wrapper {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: calc(100% - 240px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  transition: all 0.2s ease;
}

.dt-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex: 1;
  -ms-flex: 1;
  overflow: auto;
}

@media screen and (min-width: 1367px) {
  .dt-content-wrapper {
    width: calc(100% - 280px);
  }
}