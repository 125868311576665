$color-green: #52c41a;
$color-orange: #fa8c16;
$color-volcano: #fa541c;
$color-red: #f5222d;
$color-gray: #242424;
$color-dark: #262626;
$color-blue: #1890ff;
$color-cyan: #13c2c2;
$color-geekblue: #2f54eb;
$color-purple: #722ed1;
$color-magenta: #eb2f96;
$color-secondary: #8c8c8c;

$color-blue-light: #e6f7ff;
$color-gray-light: #d9d9d9;
$color-orange-light: #fff2e8;

$color-blue-dark: #003a8c;
$color-red-dark: #820014;