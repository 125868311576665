.ant-breadcrumb {
  font-size: 2.8rem;
  padding-bottom: 40px;
  line-height: normal;

  .ant-breadcrumb-link {
    color: #595959;

    a {
      color: rgba(0, 0, 0, 0.45);
      height: auto;

      &:hover {
        color: #40a9ff;
        background-color: transparent;
      }
    }
  }
}