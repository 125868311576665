.ant-tag {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 7px;
  margin: 0;
  font-weight: 400;
  border: none;
  width: fit-content;
  //height: fit-content;
  display: flex;
  align-items: center;
}