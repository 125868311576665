@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

.base-card {
  @mixin getBorder($color) {
    box-shadow: -3px 0 0 0 $color, 0px 2px 15px rgba(0, 0, 0, 0.05), rgba($color, 0.1) 0 2px 15px !important;
    background-color: mix(#fff, $color, 98%) !important;
    .ant-table-cell {
      background-color: mix(#fff, $color, 98%);
    }
  }

  border-radius: 6px !important;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05) !important;

  &.p-0 .ant-card-body {
    padding: 0 !important;
  }

  &.border-volcano {
    @include getBorder($color-volcano);
  }

  &.border-green {
    @include getBorder($color-green);
  }

  &.border-blue {
    @include getBorder($color-blue);
  }

  &.border-purple {
    @include getBorder($color-purple);
  }

  &.bg-red {
    background-color: rgba($color-red, 10%);
  }
}