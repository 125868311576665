.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 2px;
}

.ant-form-item-label > label {
  color: #8c8c8c;
  font-size: 12px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  padding-top: 4px;
}

.ant-form-item {
  margin-bottom: 16px;
}
