@import "../../../styles/vars.scss";

.color-switcher {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
  background-color: rgba($color-blue, 0);
  border: 1px solid rgba($color-blue, 0);
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &.checked {
    background-color: rgba($color-blue, 0.15);
    border-color: rgba($color-blue, 0.3);
  }
}