.backgroundWhite {
  background: white !important;
}

.labelHeader {
  display: inline-flex;
  color: #595959;
  font-size: 14px;
  margin-right: 5px;
  white-space: nowrap
}