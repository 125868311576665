.ant-table-selection-column {
  display: none;
}

.ant-table-row:nth-child(odd) td {
  background-color: #fafafa;
}

.table-selectable {
  &.ant-table-row {
    cursor: pointer;

    td {
      color: #595959 !important;
      //background-color: transparent !important;
      transition: all 0.3s ease !important;
    }

    &-selected {
      box-shadow: 2px 0 #1890ff !important;
      color: #1890ff !important;

      td {
        color: #1890ff !important;
        background-color: #e6f7ff !important;
      }
    }

    &:hover {
      &.ant-table-row-selected > td {
        background-color: #e6f7ff !important;
      }

      td {
        color: #1890ff !important;
        background-color: rgba(#e6f7ff, 0.5) !important;
      }
    }
  }
}

.table-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px solid #1890ff !important;
}

.table-drag-sorting tr.drop-over-upward td {
  border-top: 2px solid #1890ff !important;
}

.disable-sort .ant-table-column-sort {
  background-color: transparent !important;
}

.ant-table-wrapper {
  .ant-table-thead {
    >tr {
      >th, >td {
        font-weight: 500;
      }
    }
  }
}

.ui54-table {
  .ant-table-row-selected {
    .ant-table-cell {
      background-color: #abdaff !important;
    }
  }
}