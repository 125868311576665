@import '../../../styles/mixins.scss';
@import '../../../styles/vars.scss';

.radio-item {
  @mixin getRadioColor($color) {
    background-color: rgba($color, 0.06);
    color: $color;
    border-color: $color !important;

    &:hover {
      color: $color;
      background-color: rgba($color, 0.1);
    }

    &.active {
      @include getShadowBox($color)
    }

    svg.slow {
      transition: transform 0.5s cubic-bezier(.47, 1.64, .41, .8);
    }
  }

  border-radius: 4px !important;
  margin-right: 4px;
  border: 1px solid;

  &::before {
    display: none !important;
  }

  &-blue {
    @include getRadioColor($color-blue)
  }

  &-geekblue {
    @include getRadioColor($color-geekblue)
  }

  &-red {
    @include getRadioColor($color-red)
  }

  &-volcano {
    @include getRadioColor($color-volcano)
  }

  &-orange {
    @include getRadioColor($color-orange)
  }
}