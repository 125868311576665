@import '../../styles/vars.scss';

.areas_filter {
  @mixin getFilterColor($color) {
    background-color: rgba($color, 0.05);
    border-color: rgba($color, 0.3);
    transition: all 0.3s ease-in-out;

    input[type='checkbox']:checked {
      background-color: $color;
    }

    &.active {
      color: $color;
      border-color: $color;
      background-color: rgba($color, 0.1);
    }

    &:hover {
      background-color: rgba($color, 0.075);
      border-color: rgba($color, 0.6);
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    user-select: none;
    color: $color-gray;

    .isGroups {
      label {
        @include getFilterColor($color-gray)
      }
    }

    .filter-red label {
      @include getFilterColor($color-red)
    }

    .filter-blue label {
      @include getFilterColor($color-blue)
    }

    .filter-gray label {
      @include getFilterColor($color-gray)
    }

    label {
      &.ADMIN {
        @include getFilterColor($color-volcano)
      }

      &.DEFAULT {
        @include getFilterColor($color-blue)
      }

      &.BAN {
        @include getFilterColor($color-red)
      }

      &.ROOT {
        @include getFilterColor($color-purple)
      }

      &.show-more {
        @include getFilterColor($color-geekblue)
      }
    }

    .rowText-line {
      height: 1px;
      background-color: rgba($color-gray-light, 0.4);
    }

    &.isSmall {
      label {
        padding: 2px 8px;
        height: 26px;
        font-size: 14px;
      }

      input[type='checkbox']:checked {
        height: 20px;
        width: 20px;
        margin-right: 4px;
        margin-left: -4px;
      }

      svg {
        left: 4px;
      }
    }
  }

  &-container,
  &-group {
    display: flex;

    input[type='checkbox'] {
      width: 0;
      height: 0;
      border-radius: 50%;
      outline: none;
      -webkit-appearance: none;
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:checked {
        height: 26px;
        width: 26px;
        background-color: $color-blue;
        margin: 0 8px 0 0;
      }
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      height: 36px;
      border: 1px solid rgba($color-gray, 0.3);
      border-radius: 18px;
      margin: 4px;
      cursor: pointer;
      padding: 2px 12px 2px 6px;
      background-color: rgba($color-gray, 0.05);
      transition: all 0.3s ease-in-out;

      &.active {
        color: $color-blue;
        border-color: $color-blue;
        background-color: rgba($color-blue, 0.1);
      }

      &:hover {
        background-color: rgba($color-blue, 0.075);
        border-color: rgba($color-blue, 0.6);
      }
    }

    svg {
      position: absolute;
      left: 7px;
      color: #ffffff;
    }
  }

  &-group {
    margin: 4px;

    label {
      margin: 0;
      border-radius: 0;

      &:nth-child(1) {
        border-radius: 18px 0 0 18px;
      }

      &:nth-last-child(1) {
        border-radius: 0 18px 18px 0;
      }
    }
  }

  &-excluded {
    display: flex;
    cursor: pointer;
    margin: 4px;

    div {
      display: flex;
      height: 36px;
      align-items: center;
      justify-content: center;
      min-width: 36px;
      transition: all 0.15s ease-in-out;
    }

    div:nth-child(1) {
      border-top: 1px solid rgba($color-gray, 0.3);
      border-left: 1px solid rgba($color-gray, 0.3);
      border-bottom: 1px solid rgba($color-gray, 0.3);
      border-radius: 18px 0 0 18px;
      background-color: rgba($color-gray, 0.05);
    }

    div:nth-child(2) {
      border: 1px solid rgba($color-gray, 0.3);
      background-color: rgba($color-gray, 0.05);
    }

    div:nth-child(3) {
      border-top: 1px solid rgba($color-gray, 0.3);
      border-right: 1px solid rgba($color-gray, 0.3);
      border-bottom: 1px solid rgba($color-gray, 0.3);
      border-radius: 0 18px 18px 0;
      background-color: rgba($color-gray, 0.05);
    }

    &.isSmall {
      height: 26px;

      div {
        padding: 2px 6px;
        min-width: 26px;
        height: 26px;
        font-size: 14px;
      }

      div:nth-child(1) {
        padding: 0;
        border-radius: 13px 0 0 13px;
      }

      div:nth-child(3) {
        padding: 0;
        border-radius: 0 13px 13px 0;
      }
    }

    &.included {
      div:nth-child(1) {
        background-color: #52c41a;
        color: #ffffff;
        border-color: #52c41a;
      }

      div:nth-child(2) {
        background-color: rgba($color-gray, 0.05);
        color: $color-green;
        border-color: $color-green;
      }
    }

    &.excluded {
      div:nth-child(3) {
        background-color: $color-red;
        color: #ffffff;
        border-color: $color-red;
      }

      div:nth-child(2) {
        background-color: #fff1f0;
        color: $color-red;
        border-color: $color-red;
      }
    }
  }
}