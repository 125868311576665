@import '../../styles/vars.scss';

.switch-primary {
  border-radius: 4px !important;
  min-width: 54px;

  &.ant-switch-checked {
    background-color: $color-green;
  }

  .ant-switch-handle::before {
    border-radius: 2px !important;
  }
}