@import './vars.scss';
@import './mixins.scss';

html {
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #545454;
  text-align: left;
  background-color: #ffffff;
  position: relative;
  height: 100vh;
  transition: all 0.3s ease-out;
}

a {
  color: #038fde;
  text-decoration: none;
  background-color: transparent;

  &:hover, &:focus {
    outline: 0 none;
    color: #026fac;
    text-decoration: none;
  }
}

.a-qr {
  img {
    display: inline-block;
  }
}

hr {
  margin: 30px 0;
  border-color: $color-gray-light;
  transition: all 0.3s ease-out;
}

body {
  background: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 18px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: #262626;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h1,
.h1 {
  font-size: 22px;
}

th {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-gray;
  height: fit-content;

  &.header-secondary {
    color: #8c8c8c;
  }
}

h1.header-page {
  font-size: 28px;
  margin-bottom: 40px;
}

h2.header-primary {
  font-size: 26px;
  margin-bottom: 0;
}

h3.header-primary {
  font-size: 20px;
  margin-bottom: 30px;
}

h4.header-primary {
  font-size: 16px;
  margin-bottom: 0;
}

h5.header-primary {
  font-size: 14px;
  margin-bottom: 0;
}

svg {
  display: inline;
}

.p-secondary {
  line-height: 20px !important;
  color: $color-gray !important;
  font-size: 14px !important;
  font-weight: 400;
  margin-bottom: 0;
}

.text {
  &-dark-blue {
    color: $color-blue-dark !important;
  }

  &-secondary {
    color: $color-secondary !important;
  }

  &-primary {
    color: $color-gray !important;
  }

  &-red {
    color: $color-red !important;
  }

  &-volcano {
    color: $color-volcano !important;
  }

  &-orange {
    color: $color-orange !important;
  }

  &-blue {
    color: $color-blue !important;
  }

  &-green {
    color: $color-green !important;
  }

  &-purple {
    color: $color-purple !important;
  }


  &-weight {
    &-normal {
      font-weight: 400;
    }
  }

  &-size {
    &-1 {
      font-size: 5px;
    }

    &-2 {
      font-size: 7.5px;
    }

    &-3 {
      font-size: 10px;
    }

    &-4 {
      font-size: 12px;
    }

    &-5 {
      font-size: 15px;
    }
  }
}

.perms {
  @mixin getTableColor($color) {
    .ant-table-wrapper {
      box-shadow: rgba($color, 0.1) 0 10px 15px -3px,
      rgba($color, 0.05) 0 4px 6px -2px;
    }

    .perms-title {
      margin: 0;
      padding: 12px 16px;
      background-color: rgba($color, 0.2);
      font-size: 20px;
      border-radius: 4px 4px 0 0;
    }

    thead th {
      background-color: rgba($color, 0.07) !important;
      border-top: 1px solid rgba($color, 0.15) !important;
      border-radius: 0 !important;

      &:nth-last-child(1) {
        border-right: 1px solid rgba($color, 0.15) !important;
      }

      &:nth-child(-n+2) {
        border-left: 1px solid rgba($color, 0.15) !important;
      }
    }

    tr {
      background-color: rgba($color, 0.06) !important;

      &:nth-child(odd) td {
        background-color: rgba($color, 0.02) !important;
      }

      td {
        border-top: 1px solid rgba($color, 0.15) !important;

        &:nth-last-child(1) {
          border-right: 1px solid rgba($color, 0.15) !important;
        }

        &:nth-child(-n+2) {
          border-left: 1px solid rgba($color, 0.15) !important;
        }
      }

      &:nth-last-child(1) td {
        border-bottom: 1px solid rgba($color, 0.15) !important;
      }

      &:hover > td {
        background-color: transparent !important;
      }

      &.ant-table-row-selected > td {
        background-color: #e6f7ff !important;
      }
    }

    .ant-empty {
      margin: 0 !important;
    }
  }

  &-actor {
    @include getTableColor($color-blue);
  }

  &-group {
    @include getTableColor($color-volcano)
  }

  &-default {
    @include getTableColor($color-green);
  }

  &-admin {
    @include getTableColor($color-purple);
  }
}

.bg {
  @mixin getBgStyle($color) {
    background-color: rgba($color, 0.06);
    border: 1px solid rgba($color, 0.08);
    color: $color;
  }

  &-green {
    @include getBgStyle($color-green)
  }

  &-red {
    @include getBgStyle($color-red)
  }

  &-secondary {
    @include getBgStyle($color-blue-light)
  }
}

// Colors

.colorRed {
  color: #f5222d !important;
}

.colorTextGrey {
  color: #595959;
}

// Fix MD Editor

.w-md-editor-aree.w-md-editor-input {
  min-height: 300px !important;

  textarea {
    min-height: 300px !important;
  }
}

.break-word {
  word-break: break-word;
}