button.button {
  @mixin getButtonColor($color, $hover) {
    background-color: $color !important;

    &:hover {
      background-color: $hover !important;
    }

    &-outlined {
      color: $color !important;
      border-color: $color !important;
      background-color: rgba($color, 0.05) !important;

      &.dashed {
        border-style: dashed !important;
      }

      &:hover {
        color: #ffffff !important;
        background-color: $color !important;
        border-color: $color !important;
      }
    }

    &-link {
      border-radius: 4px;
      align-items: center !important;
      justify-content: center !important;
      display: inline-flex !important;
      color: $color !important;
      border: none !important;
      background-color: rgba($color, 0.05) !important;

      &.rounded {
        border-radius: 50% !important;
        min-width: 32px;
      }

      &:hover {
        color: rgba($color, 0.8) !important;
        background-color: rgba($color, 0.1) !important;
      }
    }
  }

  &-primary,
  &-secondary,
  &-warning,
  &-danger,
  &-primary-outlined,
  &-secondary-outlined,
  &-warning-outlined,
  &-danger-outlined {
    align-items: center !important;
    justify-content: center !important;
    display: inline-flex !important;
    color: #ffffff !important;
    border-color: transparent !important;
    font-weight: 400;
    border-radius: 4px;

    &.rounded {
      border-radius: 50% !important;
    }

    span {
      line-height: 1.5;
      padding-left: 2px;
    }

    &[disabled],
    &[disabled]:hover {
      color: #bfbfbfff !important;
      border-color: #bfbfbfff !important;
      background: #f5f5f5 !important;
    }

    &-outlined:hover {
      color: #ffffff !important;
    }

    &.button-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 32px;
      width: 32px;
    }
  }

  &-primary {
    @include getButtonColor(#1890ff, #096dd9);
  }

  &-secondary {
    @include getButtonColor(#8c8c8c, #096dd9);
  }

  &-warning {
    @include getButtonColor(#fa8c16, #d46b08);
  }

  &-access {
    @include getButtonColor(#52c41a, #389e0d);
  }

  &-danger {
    @include getButtonColor(#f5222d, #cf1322);
  }
}