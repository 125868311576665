ul.ant-card-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  border-radius: 0 0 6px 6px;

  li,
  span {
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    width: 100%;
  }
}