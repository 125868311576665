.col-certificate {
  position: relative;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
  height: 40px !important;
  max-width: 280px !important;
  overflow: hidden !important;
}

.col-service-uuid {
  max-width: 200px !important;
  max-height: 40px !important;
  overflow: hidden !important;
}