.avatar-user {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;

  p {
    margin: 0;
  }
}