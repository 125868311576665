.ant-pagination-item-active a {
  pointer-events: none;
}

button.ant-pagination-item {
  &-link {
    display: flex !important;
    flex-wrap: nowrap;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
}